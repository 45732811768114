<template>
  <div class="accounting-entry__wrapper">
    <v-container id="regular-tables" fluid tag="section">
      <v-row class="align-center">
        <v-col md="3">
          <h3 style="margin: 0">{{ $t("user name") }}: {{ userName }}</h3>
        </v-col>
        <v-col md="4">
          <input style="
              width: 272.22px;
              height: 40p;
              padding: 9px;
              text-align: center;
            " type="date" v-model="date" />
        </v-col>
        <v-col md="5" class="d-flex justify-end">
          <v-btn :loading="submitBtnLoading" color="green" depressed rounded class="white--text mb-5"
            @click="editAccountEntry">
            <v-icon>mdi-plus</v-icon>
            {{ $t("edit restriction") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table :dark="$store.state.isDarkMode">
        <thead class="table-heading">
          <tr>
            <th v-for="(name, i) in tableHeaders" :key="i">
              {{ $t(name) }}
            </th>
          </tr>
        </thead>
        <v-fade-transition mode="out-in" :group="true" tag="tbody">
          <tr v-for="(item, idx) in accountEntry" :key="idx">
            <td>
              <v-text-field full-width dense hide-details type="number" flat v-model="item.debit" color="#757575"
                @input="disableCredit($event, item)" :disabled="item.disableDebit"></v-text-field>
            </td>
            <td>
              <v-text-field full-width dense hide-details flat type="number" :disabled="item.disableCredit"
                v-model="item.credit" color="#757575" @input="disableDebit($event, item)"></v-text-field>
            </td>
            <td>
              <v-autocomplete full-width dense hide-details flat :items="item.row_account_items"
                :loading="item.accountItemsLoading" color="#757575" item-text="name" item-value="id"
                v-model="item.account" no-filter @keypress="fetchAutoCompleteAccount($event, item, idx)" return-object>
              </v-autocomplete>
            </td>
            <td>
              <v-text-field full-width dense hide-details flat v-model="item.desc" color="#757575"></v-text-field>
            </td>
            <td>
              <v-autocomplete full-width dense hide-details flat :items="item.row_cost_center_items" color="#757575"
                item-text="name" item-value="id" :loading="item.costCenterItemsLoading" v-model="item.cost_center"
                @keypress="fetchAutoCompleteCostCenter($event, item, idx)" no-filter @keydown.tab="handleNewRow(idx)"
                return-object clearable>
              </v-autocomplete>
            </td>
            <td>
              <v-icon @click="deleteRow(idx)" color="red"
                style="border: 1px solid red; border-radius: 50%">mdi-minus</v-icon>
            </td>
          </tr>
        </v-fade-transition>
      </v-simple-table>
    </v-container>
    <div class="account-entry__chip__wrapper">
      <div class="account-entry__chip">
        {{ $t("total debit") }}
        <span>{{ totalDebit.toLocaleString() }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t("total credit") }}
        <span>{{ totalCredit.toLocaleString() }}</span>
      </div>
      <div class="account-entry__chip">
        {{ $t("rest of the budget") }}
        <span>{{ remainingBalance.toLocaleString() }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      entryId: this.$route.params.id,
      totalCredit: 0,
      totalDebit: 0,
      remainingBalance: 0,
      tableHeaders: [
        "debtor",
        "credit",
        "account name",
        "explain",
        "cost center",
      ],
      accountEntryData: [],
      newRow: false,
      datePickerDialog: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      userName: "",
      submitBtnLoading: false,
    };
  },
  computed: {
    accountEntry() {
      if (this.newRow) {
        let entry = {
          credit: null,
          debit: null,
          disableCredit: false,
          disableDebit: false,
          type: "",
          amount: null,
          account: {},
          cost_center: {},
          row_account_items: [],
          accountItemsLoading: false,
          row_cost_center_items: [],
          costCenterItemsLoading: false,
          desc: "",
        };
        this.accountEntryData.push(entry);

        this.newRow = false;
      }
      return this.accountEntryData;
    },
  },
  methods: {
    deleteRow(index) {
      if (this.accountEntryData.length == 2) return;
      this.accountEntryData.splice(index, 1);
      this.entryCalculations();
    },
    entryCalculations() {
      this.totalCredit = 0;
      this.totalDebit = 0;
      this.remainingBalance = 0;
      this.accountEntryData.forEach((entry) => {
        if (entry.credit) {
          this.totalCredit = this.totalCredit + Number(entry.credit);
        }
        if (entry.debit) {
          this.totalDebit = this.totalDebit + Number(entry.debit);
        }
      });
      this.remainingBalance = this.totalDebit - this.totalCredit;
    },
    handleNewRow(index) {
      if (this.accountEntryData.length - 1 == index) {
        this.newRow = true;
      }
    },
    disableCredit(debitValue, row) {
      this.entryCalculations();
      if (debitValue) {
        row.disableCredit = true;
      } else {
        row.disableCredit = false;
      }
    },
    disableDebit(creditValue, row) {
      this.entryCalculations();
      if (creditValue) {
        row.disableDebit = true;
      } else {
        row.disableDebit = false;
      }
    },
    async fetchAutoCompleteCostCenter(searchQuery, item) {
      debounce(async function () {
        item.row_cost_center_items = [];
        let searchValue = searchQuery.target.value;
        item.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          item.row_cost_center_items = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          item.costCenterItemsLoading = false;
        }
      }, 500)();
    },

    async fetchAutoCompleteAccount(searchQuery, item) {
      debounce(async function () {
        item.row_account_items = [];
        let searchValue = searchQuery.target.value;
        item.accountItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          item.row_account_items = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          item.accountItemsLoading = false;
        }
      }, 500)();
    },
    async editAccountEntry() {
      try {
        this.submitBtnLoading = true;
        console.log("account entry", this.accountEntryData);
        const found = this.accountEntryData.find((entry) => {
          if (!entry.debit && !entry.credit) {
            Notifications(
              this.$t("There is an empty debtor or creditor"),
              { timeout: 5000, rtl: true },
              "error"
            );
            return entry;
          }
          if (!entry.account) {
            Notifications(
              this.$t("There is an empty account"),
              { timeout: 5000, rtl: true },
              "error"
            );
            return entry;
          }
        });
        if (found) {
          return;
        }
        if (this.remainingBalance != 0) {
          Notifications(
            this.$t("There is an empty account"),
            { timeout: 5000, rtl: true },
            "error"
          );
          return;
        }
        this.accountEntryData.forEach((entry) => {
          if (!entry.debit) {
            entry.type = "credit";
            entry.amount = Number(entry.credit);
          }
          if (!entry.credit) {
            entry.type = "debit";
            entry.amount = Number(entry.debit);
          }
          if (!entry.desc) {
            delete entry.desc;
          }
          if (entry.cost_center) {
            entry.cost_center_id = entry.cost_center.id;
          }
          entry.account_id = entry.account.id;
          delete entry.debit;
          delete entry.credit;
          delete entry.disableCredit;
          delete entry.disableDebit;
          delete entry.accountItemsLoading;
          delete entry.costCenterItemsLoading;
          delete entry.row_account_items;
          delete entry.row_cost_center_items;
          delete entry.cost_center;
          delete entry.account;
        });
        await axios.put(`/accounting/entry/${this.entryId}`, {
          date: this.date,
          rows: this.accountEntryData,
        });
        Notifications(
          this.$t("edit success"),
          { timeout: 2000, rtl: true },
          "success"
        );
        this.totalCredit = 0;
        this.totalDebit = 0;
        this.remainingBalance = 0;
        this.$router.push("/accountingEntry");
      } catch (err) {
        console.log("err", err);
      } finally {
        this.submitBtnLoading = false;
      }
    },
  },
  async created() {
    try {
      this.submitBtnLoading = true;
      const entryData = await axios.get(`/accounting/entry/${this.entryId}`);
      console.log("entry response", entryData);
      let responseData = entryData.data.data;
      this.date = responseData.date;
      this.userName = responseData.user.username;
      responseData.rows.forEach((row) => {
        row.disableCredit = false;
        row.disableDebit = false;
        row.row_account_items = [row.account];
        row.accountItemsLoading = false;
        row.row_cost_center_items = [];
        if (row.cost_center) {
          row.row_cost_center_items = [row.cost_center];
        }
        row.costCenterItemsLoading = false;
        if (row.type == "debit") {
          row.disableCredit = true;
          row.debit = row.amount;
        } else {
          row.disableDebit = true;
          row.credit = row.amount;
        }
      });
      this.accountEntryData = responseData.rows;
      this.entryCalculations();
      console.log("my arr", this.accountEntryData);
    } catch (err) {
      console.log("err", err);
    } finally {
      this.submitBtnLoading = false;
    }
  },
};
</script>

<style></style>